@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Helvetica Now Display';
  src: url('../src/fonts/HelveticaNowDisplay-Medium.ttf') format('truetype'); /* Adjust the path and filename as needed */
  /* You can include additional font styles here if you have different font weights or styles */

}
@font-face {
  font-family: 'Helvetica Now Display ExtraBold';
  src: url('../src/fonts/HelveticaNowDisplay-ExtraBold.ttf') format('truetype');
  /* Adjust the font weight if needed */
 
}
body {
  font-family:  'Helvetica Now Display';

}
.font-HelveticaNowDisplay-ExtraBold {
  font-family: 'Helvetica Now Display ExtraBold';
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#email:-webkit-autofill {
  border: 1px solid rgb(107 114 128 / var(--tw-border-opacity))  !important;
  -webkit-text-fill-color: #000000 !important;
  -webkit-box-shadow: 0 0 0px 1000px #F1EDE7 inset !important;
  box-shadow: 0 0 0px 1000px #F1EDE7 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}
#password:-webkit-autofill {
  border: 1px solid rgb(107 114 128 / var(--tw-border-opacity))  !important;
  -webkit-text-fill-color: #000000 !important;
  -webkit-box-shadow: 0 0 0px 1000px #F1EDE7 inset !important;
  box-shadow: 0 0 0px 1000px #F1EDE7 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}
:invalid {
  color: rgba(156, 163, 175, 1);
}
#min::-webkit-inner-spin-button, 
#min::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
#max::-webkit-inner-spin-button, 
#max::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
::-webkit-scrollbar {
  width: 12px;
  height: 9px;
  padding-bottom: 10px;
  background-color: #E5E5E5;
  

}
::-webkit-scrollbar-thumb {
  background-color: #BDBDBD;

  border-radius: 6px;

}

.svgicon {
  fill: #3F6AC9 !important;
}

/* .jodit-wysiwyg {
  padding: 10px; 
} */


/* .jodit-toolbar-button {
 
    background-color: rgb(66, 63, 55) !important; 
    color: white !important;

} */
.jodit-toolbar-button:active {
  background-color: black !important;
  
  color: white !important; /* Optional: Change the text color to white for better contrast */
}

.jodit-ui-button,
	.jodit-toolbar-select,
	.jodit-toolbar-button {
		--color-icon: var(--dark-icon-color);
    
		text {
			color: white;
		}

		svg,
		trigger,
		.jodit-icon {
			/* fill: white !important; */
			stroke: var(--color-icon);
		}

		
		text,
		button,
		trigger {
			&:hover:not([disabled]) {
				--color-text: white !important;
				--color-icon: white !important;
				background-color:black !important;
        opacity: 100;
				color: white !important;
			}
		}
    
	}

  .jodit-toolbar-button:active svg,
  .jodit-toolbar-button:active trigger,
  .jodit-toolbar-button:active .jodit-icon {
    fill: white !important;
  }
  .jodit-wysiwyg {
    padding-left: 10px;
}
ul {
    list-style: inside;
}
ol {
    list-style: inside;
    list-style-type: number;
}
td {
    border: solid 1px;
}
   
  
  
